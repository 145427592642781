import React, {useState, useRef, useEffect} from 'react'
import Layout from '../components/layout'
import Header from '../components/Header'
import Main from '../components/Main'
import Footer from '../components/Footer'

const IndexPage = ({location})=> {
  const [isArticleVisible, setIsArticleVisible] = useState(false);
  const [customTimeout, setCustomTimeout] = useState(false);
  const [articleTimeout, setArticleTimeout] = useState(false);
  const [article, setArticle] = useState('');
  const [loading, setLoading] = useState('is-loading');
  let wrapperRef = useRef(null);

  let timeoutId;

  useEffect(() => {
    timeoutId = setTimeout(() => {
      setLoading('')
    }, 100);
    document.addEventListener('mousedown', handleClickOutside);
  }, [])

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
    }
    document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [timeoutId])


  const setWrapperRef = (node) => {
    wrapperRef.current = node;
  }

  const handleOpenArticle = (newArticle) => {
    setIsArticleVisible(true);
    setArticle(newArticle)

    setTimeout(() => {
      setCustomTimeout(true);
    }, 325)

    setTimeout(() => {
      setArticleTimeout(true);
    }, 350)

  }

  const handleCloseArticle = () => {
    setArticleTimeout(false)

    // These timeouts are for the css fade away effects

    setTimeout(() => {
      setCustomTimeout(false)
    }, 325)

    setTimeout(() => {
      setIsArticleVisible(false);
      setArticle('')
    }, 350)

  }

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      // FIXME: isArticle visible is always false when this is called
      if (isArticleVisible) {
        handleCloseArticle();
      }
    }
  }

    return (
      <Layout location={location}>
        <div className={`body ${loading} ${isArticleVisible ? 'is-article-visible' : ''}`}>
          <div id="wrapper">
            <Header onOpenArticle={handleOpenArticle} timeout={customTimeout} />
            <Main
              isArticleVisible={isArticleVisible}
              timeout={customTimeout}
              articleTimeout={articleTimeout}
              article={article}
              onCloseArticle={handleCloseArticle}
              setWrapperRef={setWrapperRef}
            />
            <Footer timeout={customTimeout} />
          </div>
          <div id="bg"/>
        </div>
      </Layout>
    )
}

export default IndexPage
