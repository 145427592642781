import React from 'react'
import PropTypes from 'prop-types'
import paddleBoard from '../images/paddleBoard.jpg'

const Main = ({article, articleTimeout, timeout, setWrapperRef, onCloseArticle}) => {

    const close = <div className="close" onClick={() => {onCloseArticle()}}></div>

    return (
      <div ref={setWrapperRef} id="main" style={timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="work" className={`${article === 'work' ? 'active' : ''} ${articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">A quick intro</h2>
          <span className="image main"><img style={{ maxWidth:'20em', margin:'auto' }} src={paddleBoard} alt="" /></span>
          <h3>Nice to meet you!</h3>
          <strong>I'm Steven</strong>
          <p>I'm a software developer who gets stuff done. My primary focus is on the web (thats where most of my professional experience is).
            As part of a team, I am a fantastic moderator and very attentive. 
            I am great at breaking down big problems into small tasks, and prioritizing. 
            My great attention to detail and desire to deliver quality have been the key to my success.  

            It is clear that I have a passion for software and my willingness to continuously improve my skillset is an impressive asset. 
           </p>
            <p>I am currently working with <a href="https://vizworx.com" target="_blank" rel="noopener noreferrer">Vizworx Inc</a>, developing data visualizations for the <a href="https://www.cer-rec.gc.ca/en/index.html" target="_blank" rel="noopener noreferrer">Canada Energy Regulator</a>.</p>
          <p></p>
          {close}
        </article>

        <article id="contact" className={`${article === 'contact' ? 'active' : ''} ${articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <p>Don't hesitate to reach out</p>
          {close}
          <ul className="icons">
            <li><a href="https://www.linkedin.com/in/steven-rothenburger/" className="icon fa-linkedin"><span className="label">LinkedIn</span></a></li>
            <li><a href="mailto:s.rothenburger1@gmail.com" className="icon fa-envelope"><span className="label">Instagram</span></a></li>
            <li><a href="tel:+1-604-968-6743" className="icon fa-phone"><span className="label">Instagram</span></a> (604)-968-6743</li>
          </ul>
          {close}
        </article>

      </div>
    )
  
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main