import React from 'react'
import PropTypes from 'prop-types'
const year = new Date().getFullYear();

const Footer = ({timeout}) => {
    console.log(
        "!!!!!!!!!!!  Hey your pretty smart to be looking in here!  !!!!!!!!!!!")
    return (
    <footer id="footer" style={timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; Steven Rothenburger {year}</p>
    </footer>
)}

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
